<template>
    <!-- 大赛报告 阅读能力分析右侧文字分析 -->
    <div class="read-practice-data">
        <h4 class="practice-data-title">在{{reportType ? '正式比赛' : '模拟练习'}}中</h4>
        <div class="practice-data-info">
            <div class="info-item" v-for="(item,index) in readingAbilityDescArr" :key='index'>
                <p><i class="">{{item.abilityName}}</i>方面的阅读能力培养<i>最为突出</i>的是：</p>
                <span class="info-tips">{{item.userName}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            readingDescData: {
                type: Array
            },
            reportType: {
                type: Number,
                default: 0
            }
        },
        watch: {
            readingDescData: {
                handler(newVal, oldVal) {
                    // console.log('newVal', newVal)
                    if (JSON.stringify(newVal) == '[]' || !newVal) return;
                    this.readingAbilityDescArr = newVal;

                },
                immediate: true
            }
        },
        data() {
            return {
                readingAbilityDescArr: [{
                    abilityName: "提取信息",
                    rate: 0,
                    userName: "--",
                }, {
                    abilityName: "形成解释",
                    rate: 0,
                    userName: "--",
                }, {
                    abilityName: "整体感知",
                    rate: 0,
                    userName: "--",
                }, {
                    abilityName: "做出评价",
                    rate: 0,
                    userName: "--",
                }, {
                    abilityName: "完成特定任务",
                    rate: 0,
                    userName: "--",
                }]
            }
        },
    }
</script>

<style lang="less" scoped>
    .read-practice-data {
        padding: 0 12px 0 20px;

        .practice-data-title {
            margin-left: 5px;
            font-size: 14px;
            color: #666;
        }

        .practice-data-info {
            flex: 1;
            position: relative;
            box-sizing: border-box;
            border-radius: 10px;
            margin-top: 6px;
            padding: 24px 0 25px;
            width: 388px;
            line-height: 1;
            z-index: 1;
            background: #F0F8FF;

            &::before {
                position: absolute;
                z-index: -1;
                top: 20px;
                left: -12px;
                content: '';
                width: 30px;
                height: 30px;
                transform: rotate(-135deg);
                border-top-right-radius: 25%;
                background-color: #F0F8FF;
            }

            .info-item {
                font-size: 13px;
                color: #666;
                text-align: center;
                line-height: 1;

                i {
                    color: #FE8152;
                }
            }

            .info-tips {
                display: inline-block;
                border: 1px dashed #FE8152;
                border-radius: 25px;
                margin: 8px 0;
                padding: 0 18px;
                line-height: 18px;
                font-size: 13px;
                color: #FE8152;
                text-align: center;
                background: #fff;
            }
        }

        @media screen and (min-width:1250px) {
            padding: 0 12px 0 25px;

            .practice-data-title {
                font-size: 15px;
            }

            .practice-data-info {
                margin-top: 8px;
                padding: 25px 0;
                width: 470px;
                font-size: 14px;

                &::before {
                    top: 25px;
                    left: -18px;
                    width: 40px;
                    height: 40px;
                }

                .info-item {
                    font-size: 14px;
                }

                .info-tips {
                    padding: 0 22px;
                    font-size: 14px;
                    margin: 10px 0;
                    line-height: 20px;
                }
            }
        }
    }
</style>