<template>
    <div class="report-detail-simulation">
        <!-- 班级筛选 -->
        <div class="report-detail-sort">
            班级：
            <el-select v-model="classValue" placeholder="请选择" size="mini" @change="classChange">
                <el-option v-for="item in classOptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
        </div>
        <!-- 参赛人数展示 -->
        <peopleCounter :number="peopleNumber"></peopleCounter>
        <!-- 模拟学情分析 -->
        <div class="simulation-analysis">
            <div class="simulation-title common-title flex-between">
                <h3>模拟学情分析</h3>
                <div>
                    <button class="simulation-download csp">
                        <i class="el-icon-upload2"></i>导出数据</button>
                </div>
            </div>
            <ul class="simulation-data-count flex-between">
                <li v-for="(item,index) in simulationDataList" :key="index" class="simulation-data-item">
                    <h6 class="count-item-title ">{{item.title}}</h6>
                    <p class="count-item-value wes">{{item.value == undefined ? '--':item.value}}<span
                            class="count-item-unit">{{item.value == undefined ? '':item.unit}}</span>
                        <button class="result-stu-btn high-btn csp" v-if="index > 2 && item.value"
                            @click="showRank(item)">
                            <i class="el-icon-user"></i>
                            {{item.childList.length}} 名{{item.title}}
                        </button>
                    </p>
                    <ul id="stuList" class="stu-list" v-if="currentName == item.title">
                        <li v-for="each in  item.childList" :key="each.userId" class="stu-list-li flex-align-between">
                            <div class="list-avatar-wrapper">
                                <img class="stu-list-avatar csp" src="@/assets/images/course/good.png"
                                    :onerror="$store.state.user.defaultAvatar" alt />
                            </div>
                            <span class="name">{{each.userName || '--'}}</span>
                            <span>{{each.className || '--'}}</span>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="simulation-charts flex-between">
                <div class="simulation-charts-item">
                    <h4 class="charts-item-title">每日成绩对比</h4>
                    <div class="lineCharts" id="lineCharts"></div>
                </div>
                <div class="simulation-charts-item">
                    <h4 class="charts-item-title">各维度具体参与情况</h4>
                    <div class="barCharts" id="barCharts"></div>
                </div>
            </div>
        </div>
        <div class="read-analysis">
            <div class="common-title flex-between">
                <h3>阅读能力分析</h3>
            </div>
            <div class="read-data flex-between">
                <readingRadar :radarData="radarData" :radarFilterData="radarFilterData" :requestType="requestType">
                </readingRadar>
                <div class="read-data-split"></div>
                <readingDesc :readingDescData="readingDescData"></readingDesc>
            </div>
        </div>
    </div>
</template>

<script>
    import peopleCounter from '../../leader/component/report-charts/people-counter.vue'; //参赛人数
    import readingDesc from '../../leader/component/report-charts/reading-description.vue'; //阅读能力描述
    import readingRadar from '../../leader/component/report-charts/reading-radar.vue'; //阅读能力雷达图
    export default {
        components: {
            peopleCounter,
            readingRadar,
            readingDesc,
        },
        data() {
            return {
                classValue: null,
                classOptions: [{
                    id: null,
                    name: '全部'
                }],
                simulationDataList: [{
                    name: 'practiceCount',
                    title: '练习总次数',
                    value: undefined,
                    unit: '次',
                }, {
                    title: '平均每天练习次数',
                    value: undefined,
                    unit: '次',
                }, {
                    name:'practiceAvg',
                    title: '练习平均成绩', //practiceRate ÷ practiceStudentNum
                    value: undefined,
                    unit: '%',
                }, {
                    name: 'mostUserPri2',
                    title: '练习积极的学生',
                    value: undefined,
                    unit: null,
                    childList: [],
                }, {
                    name: 'bestUserPri2',
                    title: '成绩最佳的学生',
                    value: undefined,
                    unit: null,
                    childList: [],
                }],
                currentName: null,
                radarData: [], //阅读能力雷达图数据
                radarFilterData: [], //阅读能力雷达图数据 筛选
                readingDescData: [], // 雷达图右侧的各维度最突出数据
                dailyScoreData: [], //平均成绩、平均练习次数 数据  从queryInfo接口取出
                dailyScoreFilterData: [], //筛选
                dayXAxisList: [], //每日成绩对比、各维度具体参与情况 两个折线图的横轴列表
                lineDataListArray: [], //每日成绩对比series数据列表
                otherDataListArray: [], //各维度具体参与情况series数据列表
                requestType: 0,
                peopleNumber: 0,
            }
        },
        methods: {
            showRank(item) {
                if (this.currentName == item.title) {
                    this.currentName = null;
                    return;
                }
                this.currentName = item.title;
            },

            async getClassReportInfo() {
                this.readingDescData = [];
                this.requestType = this.classValue ? 1 : 2;
                let params = {
                    stageId: this.$route.params.id,
                    type: this.requestType,
                    teacherId: this.$store.state.user.userInfo.id
                    // teacherId: 56045
                }
                let resData = await this.$Api.Activity.getLeaderActiveReportInfo(params);
                console.log('resData--',resData)
                if (!resData.data || JSON.stringify(resData.data) == '[]') return;
                let dataInfo = resData.data[0];
                let handleMostAndLessData = function (item, name) {
                    if (item.name == name && dataInfo[name] != '[]' && dataInfo[name]) {
                        Vue.set(item, 'value', JSON.parse(dataInfo[name])[0].userName)
                        Vue.set(item, 'childList', JSON.parse(dataInfo[name]))
                    }

                    if (dataInfo[name] == '[]') {
                        Vue.set(item, 'value', undefined)
                        Vue.set(item, 'childList', [])
                    }
                }
                this.simulationDataList.map((item) => {
                    // 相同name的进行赋值
                    if (dataInfo.hasOwnProperty(item.name)) {
                        item.value = dataInfo[item.name]
                    }
                    // // 1.练习平均成绩  练习总成绩 ÷ 练习总人数
                    // if (item.title == '练习平均成绩') {
                    //     item.value = parseInt(dataInfo.practiceRate / dataInfo.practiceStudentNum)
                    //     if (!item.value) item.value = 0
                    // }
                    // 1.平均每天练习次数  练习总次数 ÷ 练习天数
                    if (item.title == '平均每天练习次数') {
                        item.value = parseInt(dataInfo.practiceCount / dataInfo.practiceDay)
                        if (!item.value) item.value = 0
                    }
                    // 2.练习积极的区域 
                    if (item.title == '练习积极的学生') {
                        handleMostAndLessData(item, 'mostUserPri2', dataInfo)
                    }
                    // 3.平均成绩最佳的区域
                    if (item.title == '成绩最佳的学生') {
                        handleMostAndLessData(item, 'bestUserPri2', dataInfo)
                    }
                })
                // 雷达图右侧数据
                for (let i = 1; i <= 5; i++) {
                    if (dataInfo[`practiceAbilityInfo${i}`] == '[]') return;
                    this.readingDescData.push(JSON.parse(dataInfo[`practiceAbilityInfo${i}`])[0])
                }
                // 不变和平均数
                if (this.requestType == 2) {
                    // 参与人数
                    this.peopleNumber = dataInfo.practiceStudentNum
                    // 折线图平均线数据
                    this.dailyScoreData = dataInfo.dayInfo;
                    // 雷达图数据
                    this.radarData = JSON.parse(dataInfo.practiceAbility);
                }
                // 筛选后的可变数据
                if (this.requestType == 1) {
                    this.dailyScoreFilterData = [];
                    this.radarFilterData = [];
                    // 雷达图数据
                    this.radarFilterData = JSON.parse(dataInfo.practiceAbility);
                    // 折线图平均线数据
                    this.dailyScoreFilterData = dataInfo.dayInfo;
                }

                return new Promise((resolve, reject) => {
                    resolve(resData)
                })
            },
            // 获取活动环节的时间 和 时间差值
            async getStageTime() {
                let params = {
                    stageId: this.$route.params.id
                }
                let resData = await this.$Api.Activity.getStageTime(params);
                // console.log(resData.data);
                if (!resData.data || JSON.stringify(resData.data) == '[]') return;
                // 格式化开始时间和结束时间 
                // 不格式可能由于开始时间到结束时间不到24小时而减少一天 所以必须格式化
                let startTime = this.dayjs(resData.data[0]).format('YYYY-MM-DD');
                let endTime = this.dayjs(resData.data[1]).format('YYYY-MM-DD');
                // 计算开始时间到结束时间的差值
                let dayDiff = this.dayjs(endTime).diff(this.dayjs(startTime), 'day')
                // 循环并得出折线图x轴的离散数组  
                // diff必须+1 因为要取最后一天的数据
                for (let i = 0; i < dayDiff + 1; i++) {
                    this.dayXAxisList.push(this.dayjs(startTime).add(i, 'day').format('MM月DD日'));
                }
                // console.log(this.dayXAxisList);
            },
            // 获取每日成绩对比、各维度具体参与情况 筛选后的各条线数据
            async getOtherLineInfo() {
                let params = {
                    stageId: this.$route.params.id,
                    type: 2,
                    teacherId: this.$store.state.user.userInfo.id
                    // teacherId: 56045
                }
                let resData = await this.$Api.Activity.getDaysInfo(params);
                // console.log(resData.data);
                if (JSON.stringify(resData.data) == '{}' || !resData.data) return;
                let keyList = Object.keys(resData.data);
                for (var i = 0; i < keyList.length; i++) {
                    this.lineDataListArray.push(
                        this.lineDataHandle(resData.data[keyList[i]], keyList[i], 0)
                    )
                    this.otherDataListArray.push(
                        this.lineDataHandle(resData.data[keyList[i]], keyList[i], 1)
                    )
                }
                return new Promise((resolve, reject) => {
                    resolve(resData)
                })
            },
            // 每日成绩对比数据处理,各维度具体情况数据处理
            lineDataHandle(valueList, key, type) {
                let valueMap = {}
                valueList.map(item => {
                    // 如果月份里不带0要补0
                    if (item.month.indexOf('0') == -1 && Number(item.month) < 10) {
                        item.month = `0${item.month}`
                    }
                    item.timeKey = `${item.month}月${item.day}日`
                    item.value = Number((item.rateAvg / item.participateCount).toFixed(2))
                    valueMap[item.timeKey] = type ? item.participateCount : item.value;
                })
                let lineData = []
                for (let i = 0; i < this.dayXAxisList.length; i++) {
                    if (valueMap[this.dayXAxisList[i]]) {
                        lineData.push(valueMap[this.dayXAxisList[i]])
                    } else {
                        lineData.push(0);
                    }
                }
                let obj = {}
                obj[key] = lineData;
                return obj
            },
            // 筛选里的数据
            async getTeacherClassInfo() {
                let params = {
                    stageId: this.$route.params.id
                }
                let resData = await this.$Api.Activity.getTeacherClassInfo(params)
                // console.log(resData);
                this.classOptions = this.classOptions.concat(resData.data)
            },
            classChange() {
                this.waitReturnValue();
            },
            // 等待接口完成
            waitReturnValue() {
                let avgData = this.getClassReportInfo();
                let otherData = this.getOtherLineInfo();
                Promise.all([avgData, otherData]).then((values) => {
                    // console.log(values);
                    this.initSimulationCharts('lineCharts');
                    this.initSimulationCharts('barCharts');
                });
            },
            initSimulationCharts(name) {
                var chartDom = document.getElementById(name);
                this.$echarts.init(chartDom).dispose();
                var chart = this.$echarts.init(chartDom);
                window.addEventListener('resize', () => {
                    chart.resize()
                })
                let allDataArr = [];
                let chartType = name == 'lineCharts' ? 0 : 1
                // 平均值
                let avgName = chartType ? '活动平均练习次数' : '平均成绩'
                let avgLineData = this.lineDataHandle(this.dailyScoreData, avgName, chartType);
                allDataArr.push(avgLineData);
                // 筛选后的平均值
                if (this.requestType != 2) {
                    let filterAvgName = '班级平均成绩'
                    let filterAvgLineData = this.lineDataHandle(this.dailyScoreData, filterAvgName, chartType);
                    allDataArr.push(filterAvgLineData);
                }
                // 其他数据
                let otherLineArr = chartType ? this.otherDataListArray : this.lineDataListArray;
                for (let i = 0; i < otherLineArr.length; i++) {
                    allDataArr.push(otherLineArr[i])
                }
                // 图表数据
                let series = [];
                for (let i = 0; i < allDataArr.length; i++) {
                    let key = Object.keys(allDataArr[i])[0]
                    series.push({
                        data: allDataArr[i][key],
                        type: 'line',
                        lineStyle: {
                            width: 1
                        },
                        name: key
                    })
                }
                // 为空状态
                if (JSON.stringify(this.dailyScoreData) == '[]' && JSON.stringify(otherLineArr) == '[]') {
                    series = [];
                }
                let lineOption = {
                    color: ['#EB4133', '#F8BD09', '#7832DA'],
                    tooltip: {
                        show: true
                    },
                    grid: {
                        top: "15%",
                    },
                    legend: {
                        show: true,
                        left: 'center',
                        bottom: 0,
                        itemWidth: 10,
                        itemHeight: 10,
                        icon: "rect",
                        textStyle: {
                            fontSize: 11,
                            lineHeigth: 14,
                            padding: [3, 0, 0, 0],
                            color: "#999"
                        },
                    },
                    tooltip: {
                        show: true,
                        formatter: function (params) {
                            // console.log(params);
                            return `${params.seriesName}</br>${params.marker}${params.name}：${params.value}${chartType?'次':'%'}`
                        }
                    },
                    dataZoom: [{
                        type: 'inside',
                        start: 0,
                        end: 80,
                        maxValueSpan: 6,
                        zoomOnMouseWheel: false,
                        zoomLock: true,
                        filterMode: 'none',
                    }],
                    xAxis: {
                        type: 'category',
                        data: this.dayXAxisList,
                        // max: xAxisMax,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(102, 102, 102, 0.08)"
                            },
                            interval: 0
                        },
                        axisLine: {
                            lineStyle: {
                                width: 2,
                                color: '#D3D3D3'
                            }
                        },
                        axisLabel: {
                            color: '#666666',
                            interval: 0,
                            fontSize: 10,
                            rotate: -20
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 100,
                        splitNumber: 5,
                        axisLabel: {
                            formatter: '{value} %',
                            color: '#666666',
                        },
                        splitLine: {
                            show: true,
                            interval: 1,
                            lineStyle: {
                                color: "rgba(102, 102, 102, 0.08)"
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 2,
                                color: '#D3D3D3'
                            }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    series: series
                }
                chart.setOption(lineOption)
            },
        },
        mounted() {
            this.getTeacherClassInfo();
            this.getStageTime();
            this.waitReturnValue();

        }
    }
</script>

<style lang="less" scoped>
    @import "../less/activeReport.less";
</style>