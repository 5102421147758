<template>
    <!-- 大赛报告 参赛人数-->
    <div class="report-join">
        <h4 class="join-title">参赛人数 (人)</h4>
        <span class="deadline-tips">截止到{{dayjs().format('YYYY年MM月DD日')}}00:00</span>
        <div class="join-number flex-align-between">
            <span class="number-item" v-for="(item,index) in dealtWithNumber"
                :key="index">{{dealtWithNumber[index]}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            number: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                dealtWithNumber: [0, 0, 0, 0, 0, 0] //处理后的人数
            }
        },
        watch: {
            number: {
                handler(newVal, oldVal) {
                    // console.log('newVal', newVal)
                    if (!newVal) return;
                    let numberArr = newVal.toString().split('');
                    let length = this.dealtWithNumber.length;
                    let arrDiff = this.dealtWithNumber.slice(length * -1, numberArr.length * -1)
                    this.dealtWithNumber = arrDiff.concat(numberArr);
                },
                // immediate: true

            }
        }
    }
</script>

<style lang="less" scoped>
    .report-join {
        margin-bottom: 40px;
        padding: 0 27px;
        height: 227px;
        background-image: url("~@/assets/images/course/report-bg1.png");
        background-origin: content-box;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        overflow: hidden;

        .join-title {
            margin: 30px auto;
            height: 40px;
            width: 219px;
            background-image: url("~@/assets/images/course/title-bg1.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            text-align: center;
            color: #fff;
            font-size: 15px;
            line-height: 30px;
        }

        .deadline-tips {
            position: absolute;
            top: 30px;
            right: 85px;
            color: #999999;
            font-size: 12px;
        }

        .join-number {
            padding: 0 158px;

            .number-item {
                width: 58px;
                height: 72px;
                background-color: #fff;
                color: #508EF9;
                text-align: center;
                font-size: 41px;
                line-height: 72px;
            }
        }

        @media screen and (min-width:1250px) {
            .report-join {
                margin-bottom: 40px;
                padding: 0 27px;
                height: 227px;
                background-image: url("~@/assets/images/course/report-bg1.png");
                background-origin: content-box;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                position: relative;
                overflow: hidden;

                .join-title {
                    margin: 30px auto;
                    height: 40px;
                    width: 219px;
                    background-image: url("~@/assets/images/course/title-bg1.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 15px;
                    line-height: 30px;
                }

                .deadline-tips {
                    position: absolute;
                    top: 30px;
                    right: 85px;
                    color: #999999;
                    font-size: 12px;
                }

                .join-number {
                    padding: 0 158px;

                    .number-item {
                        width: 58px;
                        height: 72px;
                        background-color: #fff;
                        color: #508EF9;
                        text-align: center;
                        font-size: 41px;
                        line-height: 72px;
                    }
                }

            }
        }
    }
</style>