<template>
    <!-- 大赛报告 雷达图 -->
    <div class="reading-chart" id="reading-chart"></div>
</template>

<script>
    export default {
        props: {
            radarData: {
                type: Array,
            },
            radarFilterData: {
                type: Array,
            },
            requestType: {
                type: Number,
            },
        },
        data() {
            return {}
        },
        watch: {
            radarData: {
                handler(newVal, oldVal) {
                    // console.log('radarData-newVal', newVal)
                    this.initRadarCharts();

                },
                immediate: false
            },
            radarFilterData: {
                handler(newVal, oldVal) {
                    // console.log('radarFilterData-newVal', newVal)
                    this.initRadarCharts();
                },
                immediate: false
            },
            // requestType: {
            //     handler(newVal, oldVal) {
            //         console.log('requestType-newVal', newVal)
            //     },
            // },
        },
        methods: {
            // 阅读能力分析雷达图
            initRadarCharts() {
                var chartDom = document.getElementById('reading-chart');
                var readingChart = this.$echarts.init(chartDom);
                window.addEventListener('resize', () => {
                    readingChart.resize()
                })
                // 活动平均数据
                let aveData = [0, 0, 0, 0, 0];
                // 雷达图指示器
                let indicator = [{
                    name: '提取信息',
                    max: 100
                }, {
                    name: '完成特定任务',
                    max: 100
                }, {
                    name: '做出评价',
                    max: 100
                }, {
                    name: '整体感知',
                    max: 100
                }, {
                    name: '形成解释',
                    max: 100
                }];
                // 维度名字数组
                let dimensions = ['提取信息', '完成特定任务', '做出评价', '整体感知', '形成解释'];
                let data = []
                if (JSON.stringify(this.radarData) != '[]' && this.radarData) {
                    indicator = [];
                    dimensions = [];
                    aveData = [];
                    this.radarData.map(item => {
                        indicator.push({
                            name: item.abilityName
                        })
                        dimensions.push(item.abilityName);
                        // 每个维度平均成绩计算
                        aveData.push(Number((item.rate / item.count).toFixed(2)))
                    })
                    // 指示器最大值计算, 选取数据最高的 * 1.2
                    indicator.map(item => {
                        item.max = 100
                        // item.max = Math.max.apply(null, aveData) * 1.2?Math.max.apply(null, aveData) * 1.2:100
                    })
                    data.push({
                        value: aveData,
                        name: '活动平均',
                        areaStyle: {
                            color: '#B9E6FC'
                        },
                        itemStyle: {
                            color: '#03ACF3'
                        }
                    })
                }
                let nameMap = {
                    3: '年级平均',
                    8: '年级平均',
                    9: '年级平均',
                    4: '学校平均',
                    5: '区域平均',
                    1: '班级平均',
                }
                if (JSON.stringify(this.radarFilterData) !== '[]' && this.radarFilterData && this.requestType != 6 &&
                    this.requestType != 2) {
                    let valueArr = []
                    this.radarFilterData.map(item => {
                        // 每个维度平均成绩计算
                        valueArr.push(Number((item.rate / item.count).toFixed(2)))
                    })
                    data.push({
                        value: valueArr,
                        name: nameMap[this.requestType],
                        areaStyle: {
                            color: '#F3DCD7'
                        },
                        itemStyle: {
                            color: '#FE8152'
                        }
                    })
                }
                let raderOption = {
                    color: ['#FE8152', '#03ACF3'],
                    legend: {
                        data: ['活动平均', nameMap[this.requestType]],
                        orient: 'vertical',
                        itemWidth: 15,
                        itemHeight: 15,
                        right: 10,
                        top: 50,
                    },
                    tooltip: {
                        show: true,
                        formatter: function (params) {
                            let str = `<span>${params.data.name}</span></br>`
                            params.dimensionNames.map((item, index) => {
                                str += `${item} ${params.value[index]}%</br>`
                            })
                            return str
                        },
                    },
                    radar: {
                        center: ['45%', '50%'],
                        indicator: indicator,
                        splitLine: {
                            lineStyle: {
                                color: '#80C3FE',
                            }
                        },
                        splitArea: {
                            areaStyle: {
                                color: ['#F0F8FF']
                            }
                        }
                    },
                    series: [{
                        name: 'Budget vs spending',
                        type: 'radar',
                        symbol: 'circle',
                        symbolSize: 8,
                        dimensions: dimensions,
                        data: data
                    }]
                }
                readingChart.setOption(raderOption)
            },
        },
    }
</script>

<style lang="less" scoped>
    .reading-chart {
        width: 493px;
        height: 296px;
        align-self: center;

        @media screen and (min-width:1250px) {
            width: 600px;
            height: 360px;
        }
    }
</style>