<template>
    <div class="report-detail-real">
        <!-- 班级筛选 -->
        <div class="report-detail-sort">
            班级：
            <el-select v-model="classValue" placeholder="请选择" size="mini" @change="classChange">
                <el-option v-for="item in classOptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
        </div>
        <!-- 参赛人数展示 -->
        <peopleCounter :number="peopleNumber"></peopleCounter>
        <!-- 正式比赛概览 -->
        <div class="real-game-data">
            <h3 class="real-title">正式比赛概览</h3>
            <ul class="real-data-count flex-between">
                <li v-for="(item,index) in realDataList" :key="index" class="real-data-item">
                    <h6 class="count-item-title">{{item.title}}</h6>
                    <p class="count-item-value wes">{{item.value == undefined ? '--':item.value}}<span
                            class="count-item-unit">{{item.value == undefined ? '':item.unit}}</span>
                        <button class="result-stu-btn high-btn csp" v-if="index > 1 && item.value != undefined"
                            @click="showRank(item)">
                            <i class="el-icon-user"></i>
                            {{item.childList.length}}名学生取得{{item.title}}
                        </button>
                    </p>
                    <ul id="stuList" class="stu-list" v-if="currentName == item.title">
                        <li v-for="each in item.childList" :key="each.userId" class="stu-list-li flex-align-between">
                            <div class="list-avatar-wrapper">
                                <img class="stu-list-avatar csp"
                                    :src="item.title == '最高成绩'? rankAvatar[0]:rankAvatar[1]"
                                    :onerror="$store.state.user.defaultAvatar" alt />
                            </div>
                            <span class="name">{{each.userName || '--'}}</span>
                            <span>{{each.className || '--'}}</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- 阅读能力分析 -->
        <div class="read-analysis">
            <div class="common-title flex-between">
                <h3>阅读能力分析</h3>
            </div>
            <div class="read-data flex-between">
                <readingRadar :radarData="radarData" :radarFilterData="radarFilterData" :requestType="requestType">
                </readingRadar>
                <div class="read-data-split"></div>
                <readingDesc :readingDescData="readingDescData"></readingDesc>
            </div>
        </div>
    </div>
</template>

<script>
    import peopleCounter from '../../leader/component/report-charts/people-counter.vue'; //参赛人数
    import readingDesc from '../../leader/component/report-charts/reading-description.vue'; //阅读能力描述
    import readingRadar from '../../leader/component/report-charts/reading-radar.vue'; //阅读能力雷达图
    export default {
        components: {
            peopleCounter,
            readingRadar,
            readingDesc,
        },
        data() {
            return {
                classValue: null,
                classOptions: [{
                    id: null,
                    name: '全部'
                }],
                realDataList: [{
                    name: 'duration',
                    title: '平均用时',
                    value: undefined,
                    unit: null,
                }, {
                    title: '平均成绩', //examRate ÷ examStudentNum
                    value: undefined,
                    unit: '%',
                }, {
                    name: 'mostRateUser',
                    title: '最高成绩',
                    value: undefined,
                    unit: '%',
                    childList: [],
                }, {
                    name: 'lessRateUser',
                    title: '最低成绩',
                    value: undefined,
                    unit: '%',
                    childList: [],
                }],
                currentName: null,
                rankAvatar: [
                    require('@/assets/images/course/good.png'),
                    require('@/assets/images/course/bad.png'),
                ],
                radarData: [], //阅读能力雷达图数据
                radarFilterData: [],
                readingDescData: [],
                peopleNumber: 0, //参与人数
                requestType: null,
            }
        },
        methods: {
            showRank(item) {
                if (this.currentName == item.title) {
                    this.currentName = null;
                    return;
                }
                this.currentName = item.title;
            },

            async getClassReportInfo() {
                this.readingDescData = [];
                this.requestType = this.classValue ? 1 : 2;
                let params = {
                    stageId: this.$route.params.id,
                    type: this.requestType,
                    teacherId: this.$store.state.user.userInfo.id
                    // teacherId: 56045
                }
                let resData = await this.$Api.Activity.getLeaderActiveReportInfo(params)
                if (!resData.data || JSON.stringify(resData.data) == '[]') return;
                let dataInfo = resData.data[0];
                let handleMostAndLessData = function (item, name) {
                    if (item.name == name && dataInfo[name] != '[]' && dataInfo[name]) {
                        Vue.set(item, 'value', JSON.parse(dataInfo[name])[0].rate)
                        Vue.set(item, 'childList', JSON.parse(dataInfo[name]))
                    }

                    if (dataInfo[name] == '[]') {
                        Vue.set(item, 'value', undefined)
                        Vue.set(item, 'childList', [])
                    }
                }
                this.realDataList.map((item) => {
                    // 相同name的进行赋值
                    if (dataInfo.hasOwnProperty(item.name)) {
                        item.value = dataInfo[item.name]
                    }
                    // 1.练习总时长 秒转分
                    if (item.name == 'duration') {
                        let avgTime = dataInfo.duration / dataInfo.examStudentNum
                        item.value = `${parseInt(avgTime / 60)}:${parseInt(avgTime%60)}`
                    }
                    // 2.练习平均成绩  练习总成绩 ÷ 练习总人数
                    if (item.title == '平均成绩') {
                        item.value = parseInt(dataInfo.examRate / dataInfo.examStudentNum)
                    }
                    // 3.练习积极的区域 
                    if (item.name == 'mostRateUser') {
                        handleMostAndLessData(item, 'mostRateUser')
                    }
                    // 4.平均成绩最佳的区域
                    if (item.name == 'lessRateUser') {
                        handleMostAndLessData(item, 'lessRateUser')
                    }
                })
                console.log(this.realDataList);
                // 雷达图右侧数据
                for (let i = 1; i <= 5; i++) {
                    if (dataInfo[`examAbilityInfo${i}`] == '[]') return;
                    this.readingDescData.push(JSON.parse(dataInfo[`practiceAbilityInfo${i}`])[0])
                }
                // 不变和平均数
                if (this.requestType == 2) {
                    // 参与人数
                    this.peopleNumber = dataInfo.examStudentNum
                    // 雷达图数据
                    this.radarData = JSON.parse(dataInfo.examAbility);
                }
                // 筛选后的可变数据
                if (this.requestType == 1) {
                    this.radarFilterData = [];
                    // 雷达图数据
                    this.radarFilterData = JSON.parse(dataInfo.examAbility);
                }

                return new Promise((resolve, reject) => {
                    resolve(resData)
                })
            },
            // 筛选里的数据
            async getTeacherClassInfo() {
                let params = {
                    stageId: this.$route.params.id
                }
                let resData = await this.$Api.Activity.getTeacherClassInfo(params)
                console.log(resData);
                this.classOptions = this.classOptions.concat(resData.data)
            },
            classChange(val) {
                this.getClassReportInfo();
            },
        },
        mounted() {
            this.getTeacherClassInfo();
            this.getClassReportInfo();
        }
    }
</script>

<style lang="less" scoped>
    @import "../less/activeReport.less";
</style>